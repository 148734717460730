import { useEffect, useState } from 'react'

export default function AnimatedCounter({ start, speed, count, suffix = `+` }) {
    const [currentValue, setCurrentValue] = useState(0)

    useEffect(() => {
        if (start && currentValue < count) {
            setTimeout(() => {
                setCurrentValue((prev) =>
                    prev + speed < count ? prev + speed : count
                )
            }, 20)
        }
    }, [currentValue, count, speed, start])
    return currentValue + suffix
}

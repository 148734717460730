import { Hidden } from '@material-ui/core'
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded'
import { Link } from 'react-router-dom'
import getStarted from '../../Assets/layout_images/getStarted.png'

export default function GetStarted({ type, color }) {
    const points = [
        'Enjoy a free online demo with our tutors until you find the right one',
        'Loved your online home tutor?',
        'We will set up your classes!'
    ]

    function getStartedPoints() {
        return (
            <div className="get_started_points">
                {points.map((el, key) => (
                    <p className="font-Quick font-med" key={key}>
                        <ArrowRightRoundedIcon
                            fontSize="large"
                            style={{
                                color: color
                            }}
                        />
                        {el}
                    </p>
                ))}
            </div>
        )
    }

    function heading() {
        return <h2 className="font-Quick font-semi">Find Your Perfect Tutor</h2>
    }

    return (
        <div
            className={`${type}_how_to_get_started_section how_to_get_started_section`}
        >
            <Hidden mdUp>
                <div
                    className={`${type}_how_to_get_started_section_mob how_to_get_started_section_mob`}
                >
                    {heading()}
                    <div>
                        <div className="image_mob_get_started">
                            <img src={getStarted} alt="get started" />
                        </div>
                        {getStartedPoints()}
                    </div>
                </div>
            </Hidden>
            <Hidden smDown>
                <div
                    className={`${type}_how_to_get_started_section_desk how_to_get_started_section_desk`}
                >
                    <img src={getStarted} alt="getStarted" />
                    <div>
                        {heading()}
                        {getStartedPoints()}

                        <div className={`get_started_button_${type}`}>
                            <Link
                                className={`${type}_book_button font-Quick font-bold`}
                                to="/form"
                            >
                                Book A Free Demo Class
                            </Link>
                        </div>
                    </div>
                </div>
            </Hidden>
        </div>
    )
}

import { Helmet } from 'react-helmet'

export default function HelmetGenerator({ details }) {
    return (
        <Helmet>
            {!!details.title && <title>{details.title}</title>}
            <meta charSet="utf-8" />
            {!!details.description && (
                <meta name="description" content={details.description} />
            )}
            {!!details.keywords && (
                <meta name="keywords" content={details.keywords} />
            )}
            {!!details.canonical && (
                <link rel="canonical" href={details.canonical} />
            )}
        </Helmet>
    )
}

import Main from './components/Main'
import './style.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { AuthContextProvider } from './components/contexts/AuthContext'

function App() {
    return (
        <AuthContextProvider>                     
            <Main />
        </AuthContextProvider>
    )
}

export default App

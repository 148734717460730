import attentionSchool from '../../Assets/layout_images/attentionSchool.svg'
import crashSchool from '../../Assets/layout_images/crashSchool.svg'
import winnerSchool from '../../Assets/layout_images/winnerSchool.svg'
import wonSchool from '../../Assets/layout_images/wonSchool.svg'
import notesSchool from '../../Assets/layout_images/notesSchool.svg'
import mediumSchool from '../../Assets/layout_images/mediumSchool.svg'
import examsSchool from '../../Assets/layout_images/examsSchool.svg'
import textbook from '../../Assets/layout_images/textbook.svg'

export const benefits = {
    state: [
        {
            image: attentionSchool,
            title: 'Individual Attention',
            content: 'One Student. One Teacher.'
        },
        {
            image: crashSchool,
            title: 'Crash Courses',
            content: 'For those who require urgent help during exams.'
        },
        {
            image: textbook,
            title: 'Good grip on your Textbook',
            content:
                'Get a good grip on SCERT Textbooks Get tutored only for your most difficult chapters.'
        },
        {
            image: examsSchool,
            title: 'Tests',
            content: 'Regular Tests and assignments.'
        },
        {
            image: notesSchool,
            title: 'Get important notes ',
            content:
                'Get helpful tips, tricks, and learning techniques for Kerala State Board Text Books.'
        },
        {
            image: mediumSchool,
            title: 'All Medium',
            content:
                'Get help for both English Medium and Malayalam Medium classes.'
        },
        {
            image: wonSchool,
            title: 'Conquer every exam',
            content: 'Get support for all your difficult subjects.'
        }
    ],
    cbse: {
        class7: [
            {
                image: attentionSchool,
                title: 'Individual Attention',
                content:
                    'We provide Personalized Online tuition through 1 on 1 classes.'
            },
            {
                image: crashSchool,
                title: 'Quick Study Option',
                content:
                    'Get instant support through individual online tuition for exams.'
            },
            {
                image: textbook,
                title: 'Become a Textbook Pro',
                content:
                    'Grasp CBSE textbook concepts and practical uses with online.'
            },
            {
                image: examsSchool,
                title: 'Online Evaluation',
                content: 'Get smarter with regular revisions and Grow Plan.'
            },
            {
                image: notesSchool,
                title: 'Grab Key Insights',
                content:
                    'Access tips and learning techniques for CBSE textbooks with online CBSE tuition.'
            },
            {
                image: winnerSchool,
                title: 'Weak fundamentals?',
                content:
                    'Work on your fundamentals with a home tutor to score higher.'
            },
            {
                image: wonSchool,
                title: 'A Grade Guarantee',
                content:
                    'Conquer difficult subjects with anytime support from your online home tuition.'
            }
        ],
        class8: [
            {
                image: attentionSchool,
                title: 'Individual Attention',
                content:
                    'We provide Personalized Online tuition through 1 on 1 classes.'
            },
            {
                image: crashSchool,
                title: 'Quick Study Option',
                content:
                    'Get instant support through individual online tuition for exams.'
            },
            {
                image: textbook,
                title: 'Become a Textbook Pro',
                content:
                    'Grasp CBSE textbook concepts and practical uses with online.'
            },
            {
                image: examsSchool,
                title: 'Online Evaluation',
                content: 'Get smarter with regular revisions and Grow Plan.'
            },
            {
                image: notesSchool,
                title: 'Grab Key Insights',
                content:
                    'Access tips and learning techniques for CBSE textbooks with online CBSE tuition.'
            },
            {
                image: winnerSchool,
                title: 'Weak fundamentals?',
                content:
                    'Work on your fundamentals with a home tutor to score higher.'
            },
            {
                image: wonSchool,
                title: 'A Grade Guarantee',
                content:
                    'Conquer difficult subjects with anytime support from your online home tuition.'
            }
        ]
    },
    icse: {
        class7: [
            {
                image: attentionSchool,
                title: 'Individual Attention',
                content:
                    'We provide Personalized Online tuition through 1 on 1 classes.'
            },
            {
                image: crashSchool,
                title: 'Quick Study Option',
                content:
                    'Get instant support through individual online tuition for exams.'
            },
            {
                image: textbook,
                title: 'Become a Textbook Pro',
                content:
                    'Grasp ICSE textbook concepts and practical uses with online.'
            },
            {
                image: examsSchool,
                title: 'Grow Plan',
                content:
                    'Get smarter with regular revisions and personalized ‘Grow Plan’.'
            },
            {
                image: notesSchool,
                title: 'Get Notes of ICSE Syllabus',
                content:
                    'Access tips and learning techniques for ICSE textbooks with online ICSE tuition.'
            },
            {
                image: winnerSchool,
                title: 'Weak ICSE fundamentals?',
                content:
                    'Get  exclusive sessions to strengthen your fundamentals of ICSE Syllabus.'
            },
            {
                image: wonSchool,
                title: 'Flexible timings',
                content:
                    'Online home tutor provides you with classes as per your convenience.'
            }
        ],
        class8: [
            {
                image: attentionSchool,
                title: 'Individual Attention',
                content:
                    'We provide Personalized Online tuition through 1 on 1 classes.'
            },
            {
                image: crashSchool,
                title: 'Quick Study Option',
                content:
                    'Get instant support through individual online tuition for exams.'
            },
            {
                image: textbook,
                title: 'Become a Textbook Pro',
                content:
                    'Grasp ICSE textbook concepts and practical uses with online.'
            },
            {
                image: examsSchool,
                title: 'Grow Plan',
                content:
                    'Get smarter with regular revisions and personalized ‘Grow Plan’.'
            },
            {
                image: notesSchool,
                title: 'Get Notes of ICSE Syllabus',
                content:
                    'Access tips and learning techniques for ICSE textbooks with online ICSE tuition.'
            },
            {
                image: winnerSchool,
                title: 'Weak ICSE fundamentals?',
                content:
                    'Get  exclusive sessions to strengthen your fundamentals of ICSE Syllabus.'
            },
            {
                image: wonSchool,
                title: 'Flexible timings',
                content:
                    'Online home tutor provides you with classes as per your convenience.'
            }
        ]
    },
    igcse: {
        class7: [
            {
                image: attentionSchool,
                title: 'Individual Attention',
                content:
                    'One Student. One Teacher.'
            },
            {
                image: crashSchool,
                title: 'Crash Courses',
                content:
                    'For those who require urgent help during exams.'
            },
            {
                image: textbook,
                title: 'Good grip on your Textbook',
                content:
                    'Become an expert in your textbooks and gain practical examples also.'
            },
            {
                image: examsSchool,
                title: 'Tests',
                content:
                    'Regular Tests and assignments.'
            },
            {
                image: notesSchool,
                title: 'Get important notes',
                content:
                    'Get helpful tips, tricks, and learning techniques for NCERT and Prescribed textbooks for ICSE & IGCSE.'
            },
            {
                image: winnerSchool,
                title: 'Get your competitive Edge',
                content:
                    'Score good marks by strengthening your fundamentals.'
            },
            {
                image: wonSchool,
                title: 'Conquer every exam',
                content:
                    'Get support for all your difficult subjects.'
            }
        ],
        class8: [
            {
                image: attentionSchool,
                title: 'Individual Attention',
                content:
                    'One Student. One Teacher.'
            },
            {
                image: crashSchool,
                title: 'Crash Courses',
                content:
                    'For those who require urgent help during exams.'
            },
            {
                image: textbook,
                title: 'Good grip on your Textbook',
                content:
                    'Become an expert in your textbooks and gain practical examples also.'
            },
            {
                image: examsSchool,
                title: 'Tests',
                content:
                    'Regular Tests and assignments.'
            },
            {
                image: notesSchool,
                title: 'Get important notes',
                content:
                    'Get helpful tips, tricks, and learning techniques for NCERT and Prescribed textbooks for ICSE & IGCSE.'
            },
            {
                image: winnerSchool,
                title: 'Get your competitive Edge',
                content:
                    'Score good marks by strengthening your fundamentals.'
            },
            {
                image: wonSchool,
                title: 'Conquer every exam',
                content:
                    'Get support for all your difficult subjects.'
            }
        ]
    },
}

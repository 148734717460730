import style from './loading_animation.module.css'

export default function LoadingAnimation() {
    return (
        <div className={style.loadingWrapper}>
            <div className={style.spinner} style={{ fontSize: '10px' }}>
                <div className={style.head}></div>
            </div>
        </div>
    )
}

export default function AboutOurTutorsFooter() {
    return (
        <div className="about_our_tutor_footer">
            <h4 className="font-Quick font-med">About our tutors</h4>
            <p className="font-Quick font-reg">
                A comprehensive interview process is used to select skilled
                tutors. Our tutors connect with the students emotionally. Our
                tutors can help any student boost their grades by offering live
                as well as individual attention.
                <br />
                <br />
                Tutors are highly qualified to teach CBSE, STATE, ICSE
                syllabuses. We also have dedicated tutors to tutor B.Tech
                graduates. Any student who needs extensive exam revisions or
                crash courses will find teacherInd very useful.
                <br />
                <br />
                Students who have difficulty in capturing engineering concepts
                and formulas will get a huge relief from our tutors. They need
                not fear asking doubts in a classroom full of students as all
                tuitions are individual classes. All students can get their
                fundamentals cleared through flexible timings and any number of
                on-demand classes.
            </p>
        </div>
    )
}

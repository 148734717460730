import { useLocation } from 'react-router-dom'
import FaqSingle from './FaqSingle'

export default function FaqContainer({ faqList }) {
    const location = useLocation()
    const pathnameList = location.pathname.split('/')
    const mainPath = pathnameList[1]
    const subPath = pathnameList[2]
    const isClass8 = subPath ? subPath.includes('8') : false

    let faqListToRender = []

    if (mainPath === 'cbse' || mainPath === 'icse' || mainPath === 'igcse') {
        faqListToRender = isClass8 ? faqList.class8 : faqList.class7
    } else {
        faqListToRender = faqList
    }

    return (
        <div className="all_faqs_section">
            <h2 className="font-Quick font-semi">Frequently Asked Questions</h2>
            <div>
                {faqListToRender?.map((item, key) => (
                    <FaqSingle
                        key={key}
                        number={key + 1}
                        qn={item.qn}
                        ans={item.ans}
                    />
                ))}
            </div>
        </div>
    )
}

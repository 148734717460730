import { Link, NavLink, useLocation } from 'react-router-dom'
import teacherind from '../../Assets/logos/logo.svg'
import Hidden from '@material-ui/core/Hidden'
import { useState } from 'react'
import { navButtonClass } from '../utils/navButtonClass'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded'
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded'
import Social from '../../Assets/layout_icons/social.png'
import Phone from '../../Assets/layout_icons/phone-call.png'

export default function Header() {
    const [hamburgerStatus, setHamburgerStatus] = useState(false)

    const navList = [
        ['Home', '/'],
        ['School', ''],
        ['Spoken English', '/spoken-english'],
        ['Tutors', ''],
        // ['Robotics', '/robotics'],
        ['More', '']
    ]
    const navListMob = [
        ['Home', '/'],
        ['B.Tech', '/btech'],
        ['State', '/state'],
        ['CBSE', '/cbse'],
        ['ICSE', '/icse'],
        ['IGCSE', '/igcse'],
        ['Spoken English', '/spoken-english'],
        ['Become a tutor', '/tutor'],
        ['Privacy Policy', '/privacy-policy'],
        ['Contact Us', '/contact-us'],
        ['Blog', '/blogs']
    ]

    const grades = () => {
        let ar = []
        for (let i = 0; i < 12; i++) {
            ar.push({
                grade: `Class ${i + 1}`,
                subCategory: ['ICSE', 'State', 'CBSE', 'IGCSE']
            })
        }
        return ar
    }

    const categories = {
        ICSE: '/icse',
        State: '/state',
        CBSE: '/cbse',
        IGCSE: '/igcse'
    }

    const location = useLocation()

    return (
        <div className="header__section">
            <Link to="/">
                <img className="nav__logo" src={teacherind} alt="teacherind" />
            </Link>
            <Hidden smDown>
                <nav>
                    <ul className="main_nav_ul">
                        {navList.map((item, key) => (
                            <li key={key}>
                                {!!item[1] && (
                                    <NavLink
                                        className="font-Quick font-15 font-med"
                                        to={item[1]}
                                    >
                                        {item[0]}
                                    </NavLink>
                                )}
                                {item[0] === 'School' && (
                                    <div className="drop_nav">
                                        <p className="font-Quick font-15 font-med">
                                            {item[0]}
                                        </p>
                                        <ArrowDropDownRoundedIcon />
                                        {/* Class and Category */}
                                        <div className="wrapper_sub_nav">
                                            <ul className="sub__nav">
                                                {grades().map((el, key) => (
                                                    <li key={key}>
                                                        <ArrowRightRoundedIcon />
                                                        <p className="font-Quick font-15 font-med">
                                                            {el.grade}
                                                        </p>
                                                        <div className="wrapper_sub_sub_nav">
                                                            <ul>
                                                                {el.subCategory.map(
                                                                    (
                                                                        innerEl,
                                                                        index
                                                                    ) => (
                                                                        <li
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <NavLink
                                                                                className="font-Quick font-15 font-med"
                                                                                to={`${
                                                                                    categories[
                                                                                        innerEl
                                                                                    ]
                                                                                }/${el.grade
                                                                                    .toLowerCase()
                                                                                    .split(
                                                                                        ' '
                                                                                    )
                                                                                    .join(
                                                                                        ''
                                                                                    )}`}
                                                                            >
                                                                                {' '}
                                                                                <ArrowRightRoundedIcon />
                                                                                {
                                                                                    innerEl
                                                                                }
                                                                            </NavLink>
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                {item[0] === 'Tutors' && (
                                    <div className="drop_nav">
                                        <p className="font-Quick font-15 font-med">
                                            {item[0]}
                                        </p>
                                        <ArrowDropDownRoundedIcon />
                                        {/* Tutor */}
                                        <div className="wrapper_sub_nav">
                                            <ul className="sub__nav sub__nav__padding">
                                                <li>
                                                    <ArrowRightRoundedIcon />
                                                    <NavLink
                                                        to="/tutor"
                                                        className="font-Quick font-15 font-med"
                                                    >
                                                        Become a tutor
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                {item[0] === 'More' && (
                                    <div className="drop_nav">
                                        <p className="font-Quick font-15 font-med">
                                            {item[0]}
                                        </p>
                                        <ArrowDropDownRoundedIcon />

                                        {/* More */}
                                        <div className="wrapper_sub_nav">
                                            <ul className="sub__nav sub__nav__padding">
                                                {[
                                                    [
                                                        'Contact Us',
                                                        '/contact-us'
                                                    ],
                                                    [
                                                        'Privacy policy',
                                                        '/privacy-policy'
                                                    ],
                                                    ['Blog', '/blogs']
                                                ].map((el, key) => (
                                                    <li key={key}>
                                                        <ArrowRightRoundedIcon />
                                                        <NavLink
                                                            to={el[1]}
                                                            className="font-Quick font-15 font-med"
                                                        >
                                                            {el[0]}
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </Hidden>

            <Hidden smDown>
                {(location.pathname === '/tutor' && (
                    <Link
                        to="/tutor/register"
                        className={navButtonClass(location.pathname, false)}
                    >
                        Apply Now
                    </Link>
                )) || (
                    <Link
                        className={navButtonClass(location.pathname, false)}
                        to="/form"
                    >
                        Book A Free Class
                    </Link>
                )}
            </Hidden>

            <Hidden mdUp>
                <nav>
                    <ul
                        onClick={() => setHamburgerStatus((prev) => !prev)}
                        className={
                            hamburgerStatus
                                ? 'main_nav_mob_ul'
                                : 'main_nav_mob_ul main_nav_mob_ul_hidden'
                        }
                    >
                        {navListMob.map((item, key) => (
                            <li key={key}>
                                {!!item[1] && (
                                    <NavLink
                                        className="font-Quick font-15 font-med"
                                        to={item[1]}
                                    >
                                        {item[0]}
                                    </NavLink>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </Hidden>
            <Hidden mdUp>
                <div
                    onClick={() => setHamburgerStatus((prev) => !prev)}
                    className={
                        hamburgerStatus ? 'hamburger ham_active' : 'hamburger'
                    }
                >
                    <span></span>
                    <span></span>
                </div>
                {location.pathname !== '/tutor/register' && (
                    <div className="bottom_wrapper">
                        <a href="tel:+917907361068" className='phone'><img src={Phone} alt="phone"/></a>
                        <a href="https://wa.me/+917907361068" className='whatsapp'><img src={Social} alt="whatsapp"/></a>
                        {(location.pathname === '/tutor' && (
                            <Link
                                to="/tutor/register"
                                className={navButtonClass(
                                    location.pathname,
                                    true
                                )}
                            >
                                Apply Now
                            </Link>
                        )) || (
                            <Link
                                className={navButtonClass(
                                    location.pathname,
                                    true
                                )}
                                to="/form"
                            >
                                Book A Free Class
                            </Link>
                        )}
                    </div>
                )}
            </Hidden>
        </div>
    )
}

export default function FormSubProgramFIeld({
    program,
    subProgram,
    handleChange
}) {
    function get2InputField() {
        if (!!program) {
            if (program === 'School') {
                return (
                    <div className="input_field">
                        <select
                            className="font-Quick font-med"
                            name="subProgram"
                            value={subProgram}
                            onChange={handleChange}
                        >
                            <option value="">Select Class</option>
                            {[
                                'Class 1',
                                'Class 2',
                                'Class 3',
                                'Class 4',
                                'Class 5',
                                'Class 6',
                                'Class 7',
                                'Class 8',
                                'Class 9',
                                'Class 10',
                                'Class 11',
                                'Class 12'
                            ].map((item, key) => (
                                <option value={item} key={key}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                )
            } else if (program === 'B.tech') {
                return (
                    <div className="input_field">
                        <select
                            name="subProgram"
                            className="font-Quick font-med"
                            value={subProgram}
                            onChange={handleChange}
                        >
                            <option value="">Select Stream</option>
                            {[
                                `Civil Engineering`,
                                `Computer Science & Engineering`,
                                `Electrical & Electronics Engineering`,
                                `Mechanical Engineering`,
                                `Electronics & Communication`
                            ].map((item, key) => (
                                <option value={item} key={key}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                )
            } else if (program === 'Robotics' || program === 'Spoken English') {
                return (
                    <div className="input_field">
                        <input
                            value={subProgram}
                            name="subProgram"
                            onChange={handleChange}
                            className="font-Quick font-med"
                            placeholder="Enter Age"
                            type="number"
                        />
                    </div>
                )
            }
        } else {
            return (
                <div className="input_field">
                    <select className="font-Quick font-med" value="" disabled>
                        <option value="">Select ...</option>
                    </select>
                </div>
            )
        }
    }
    return get2InputField()
}

export const navButtonClass = (locPath, mobile = false) => {
    let newClass = mobile
        ? 'nav_button_bottom font-Quick font-bold font-14 '
        : 'nav_button_header font-Quick font-bold font-16 '

    if (locPath === '/btech') {
        newClass += 'btech_nav_color'
    } else if (
        locPath.includes('/state') ||
        locPath.includes('/cbse') ||
        locPath.includes('/icse') ||
        locPath.includes('/igcse') ||
        locPath === '/spoken-english'
    ) {
        newClass += 'state_nav_color'
    } else if (locPath === '/tutor/register' || locPath === '/tutor') {
        newClass += 'tutor_nav_color'
    } else if (locPath === '/robotics') {
        newClass += 'robotics_nav_color'
    } else {
        newClass += 'home_nav_color'
    }
    return newClass
}

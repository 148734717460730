import { Container, Grid } from '@material-ui/core'
import wow_girl from '../../Assets/layout_images/wow_girl.png'
import learnSpeed from '../../Assets/layout_icons/learn_speed.svg'
import homeWorkAssist from '../../Assets/layout_icons/homeWorkAssist.svg'
import language from '../../Assets/layout_icons/language.svg'
import doubt from '../../Assets/layout_icons/doubt.svg'
import bulb from '../../Assets/layout_icons/bulb.svg'
import revision from '../../Assets/layout_icons/revision.svg'
import crashCourse from '../../Assets/layout_icons/crashCourse.svg'
import individualAttention from '../../Assets/layout_icons/individualAttention.svg'
import Slider from 'react-slick'

export default function BenefitsHome() {
    const points = [
        {
            img: learnSpeed,
            text: 'Learn at your own pace'
        },
        {
            img: homeWorkAssist,
            text: 'Get help for Home Works and Class tests'
        },
        {
            img: language,
            text: ' Language friendly'
        },
        {
            img: doubt,
            text: 'Ask any silly doubt'
        },
        {
            img: bulb,
            text: 'Get Preparation tips and tricks'
        },
        {
            img: revision,
            text: 'Revisions for exams'
        },
        {
            img: crashCourse,
            text: 'Crash Courses'
        },
        {
            img: individualAttention,
            text: 'Individual Attention'
        }
    ]

    var settings = {
        dots: false,
        infinite: true,
        autoplaySpeed: 1000,
        autoplay: true,
        slidesToShow: 4,
        vertical: true,
        verticalSwiping: true,
        slidesToScroll: 1,
        cssEase: 'ease-in-out',
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    dots: true,
                    autoplaySpeed: 2000,
                    vertical: false,
                    verticalSwiping: false,
                    slidesToShow: 2,
                    rows: 2,
                    swipeToSlide: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    autoplaySpeed: 2000,
                    vertical: false,
                    verticalSwiping: false,
                    slidesToShow: 1,
                    rows: 2,
                    swipeToSlide: true
                }
            }
        ]
    }

    return (
        <div className="benefits_home">
            <Container>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12} md={7}>
                        <div className="benefits_home_image">
                            <div>
                                <img src={wow_girl} alt="wow girl" />
                                <div>
                                    <h2 className="font-Quick font-semi">
                                        Benefits of Online Tuition
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <div className="slider_points_benefits_home">
                            <Slider {...settings}>
                                {points.map((el, key) => (
                                    <div key={key}>
                                        <div className="point_wrapper">
                                            <div>
                                                <img
                                                    src={el.img}
                                                    alt={el.text}
                                                />

                                                <p className="font-Quick font-med">
                                                    {el.text}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

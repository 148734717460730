export default function AboutUsFooter() {
    return (
        <div className="about_us_footer">
            <h4 className="font-Quick font-med">About Us</h4>
            <p className="font-Quick font-reg">
                TeacherInd offers the best individual online tutoring and
                mentoring services for students in the K-12 sector and
                Professional Colleges like B.Tech. All our tutors are selected
                through a rigorous screening process and top quality is
                guaranteed. Students of classes 1-7 can visit teacherInd to get
                online 1:1 tuitions for Math, Science, Hindi, English, EVS,
                Social Studies, Malayalam. Students of classes 8-10 can visit
                teacherInd to get online 1:1 tuitions for Math, Science, Hindi,
                English, physics, chemistry, biology etc. Class 11-12 can get
                tuitions for Mathematics, Physics, Chemistry, English, Biology,
                Computer Science, Accountancy, etc. Engineering students
                pursuing Bachelor of Technology can avail tuition for all
                branches of study.
            </p>
            <div></div>
        </div>
    )
}

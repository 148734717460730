import logo from '../../Assets/logos/logo.svg'
import mailIcon from '../../Assets/layout_icons/mailIcon.svg'
import whatsappIcon from '../../Assets/layout_icons/whatsappIcon.svg'
import callIcon from '../../Assets/layout_icons/callIcon.svg'
import { Link } from 'react-router-dom'

export default function ContactInForm({ form }) {
    const contacts = [
        {
            icon: mailIcon,
            link: 'mailto:teacherindedu@gmail.com',
            text: 'teacherindedu@gmail.com'
        },
        {
            icon: whatsappIcon,
            link: 'https://wa.me/+917907361068',
            text: '+91 7907361068'
        },
        {
            icon: callIcon,
            link: 'tel:+917907361068',
            text: '+91 7907361068'
        }
    ]
    return (
        <div className="contact_form_details_header">
            {(!form && (
                <Link to="/" className="logo_contact_form">
                    <img src={logo} alt="logo" />
                </Link>
            )) || (
                <div className="logo_contact_not_in_form">
                    <img src={logo} alt="logo" />
                    <p className="font-Quick font-med">
                        Kerala’s Leading One to One Tuition Platform
                    </p>
                </div>
            )}

            {!form && (
                <div className="contact_in_form">
                    {contacts.map((item, key) => (
                        <a
                            key={key}
                            target="_blank"
                            rel="noreferrer"
                            href={item.link}
                            className="font-Quick font-med"
                        >
                            <img src={item.icon} alt={item.text} />
                            {item.text}
                        </a>
                    ))}
                </div>
            )}
        </div>
    )
}

import { Redirect, Route } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'

export default function ProtectedRoute({ component: Component, ...rest }) {
    const { user } = useAuthContext()

    return (
        <Route
            {...rest}
            render={(props) =>
                !!user ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/admin/login" />
                )
            }
        />
    )
}

export function validateEmail(email) {
    const emailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if (email.match(emailFormat)) {
        return true
    } else {
        return false
    }
}

export function validatePhoneNumber(phone) {
    if (parseInt(phone) > 0 && phone.toString().length === 10) {
        return true
    } else {
        return false
    }
}
export function validateTexts(text) {
    if (!!text) {
        return true
    } else {
        return false
    }
}

// export function validateOTP(otp) {
//     if (otp.toString().length === 4) {
//         return true
//     } else {
//         return false
//     }
// }

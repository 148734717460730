import { Container, Hidden } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import AboutOurTutorsFooter from './AboutOurTutorsFooter'
import AboutUsFooter from './AboutUsFooter'
import IdealForStudentsFooter from './IdealForStudentsFooter'
import QuickLinksFooter from './QuickLinksFooter'
import SocialMediaFooter from './SocialMediaFooter'
import Social from '../../Assets/layout_icons/social.png'
import Phone from '../../Assets/layout_icons/phone-call.png'

export default function Footer() {
    const location = useLocation()

    return (
        <footer className="footer-section">
            <div className="floating-menu phone">
                <a target="_blank" href="tel:+917907361068"><img src={Phone}/></a>
            </div>
            <div className="floating-menu whatsapp">
                <a target="_blank" href="https://wa.me/+917907361068"><img src={Social}/></a>
            </div>           
            <Container>
                <Hidden smDown>
                    <div className="font-Quick font-bold book_free_class_button_footer">
                        <Link to="/form">Book A Free Class</Link>
                    </div>
                </Hidden>

                {location.pathname === '/' && <AboutUsFooter />}
                <QuickLinksFooter />
                <SocialMediaFooter />

                <Hidden mdUp>
                    <div className="button_download_app_footer">
                        <a
                            className="font-Quick font-bold"
                            target="_blank"
                            rel="noreferrer"
                            href="https://play.google.com/store/apps/details?id=co.davos.irvkq"
                        >
                            Download App
                        </a>
                    </div>
                </Hidden>

                {location.pathname === '/' && (
                    <>
                        <AboutOurTutorsFooter />
                        <IdealForStudentsFooter />
                    </>
                )}
            </Container>
            <div className="copyright_footer font-Quick font-med font-14">
                &#169; 2021-2024 TeacherInd.com
            </div>
        </footer>
    )
}

export const linksInFooter = {
    syllabus: {
        title: 'Syllabus',
        links: [
            ['CBSE', '/cbse'],
            ['State', '/state'],
            ['IGCSE', '/igcse'],
            ['ICSE', '/icse']
        ]
    },
    courses: {
        title: 'Courses',
        links: [
            ['School', '/cbse'],
            ['Spoken English', '/spoken-english'],
            // ['Robotics', '/robotics']
        ]
    },

    // btech: {
    //     title: 'B.Tech',
    //     links: [
    //         [`Civil Engineering`, '/btech/civil'],
    //         [`Computer Science & Engineering`, '/btech/computer-science'],
    //         [
    //             `Electrical & Electronics Engineering`,
    //             '/btech/electrical-and-electronics'
    //         ],
    //         [`Mechanical Engineering`, '/btech/mechanical'],
    //         [
    //             `Electronics & Communication`,
    //             '/btech/electronics-and-communication'
    //         ]
    //     ]
    // },
    skillDevelopment: {
        title: 'Skill Development',
        links: [
            // ['Robotics', '/robotics'],
            ['Spoken English', '/spoken-english']
        ]
    },
    quickLinks: {
        title: 'Quick Links',
        links: [
            ['Home', '/'],
            ['Privacy Policy', '/privacy-policy'],
            ['Contact Us', '/contact-us'],
            ['Tutors', '/tutor'],
            // ['B.Tech', '/btech'],
            ['FAQs', '/faqs']
        ]
    },
    state: {
        title: 'State',
        links: [
            ['Class 1 - 7', '/state/class1-7'],
            ['Class 8 - 10', '/state/class8-10'],
            ['Class 11 & 12', '/state/class11-12']
        ]
    },
    cbse: {
        title: 'CBSE',
        links: [
            ['Class 1 - 7', '/cbse/class1-7'],
            ['Class 8 - 10', '/cbse/class8-10'],
            ['Class 11 & 12', '/cbse/class11-12']
        ]
    },
    igsce: {
        title: 'IGCSE',
        links: [
            ['Class 1 - 7', '/igcse/class1-7'],
            ['Class 8 - 10', '/igcse/class8-10'],
            ['Class 11 & 12', '/igcse/class11-12']
        ]
    },
    icse: {
        title: 'ICSE',
        links: [
            ['Class 1 - 7', '/icse/class1-7'],
            ['Class 8 - 10', '/icse/class8-10'],
            ['Class 11 & 12', '/icse/class11-12']
        ]
    }
}

import { Container } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { DB } from '../../firebase'
import HelmetGenerator from '../partialComponents/HelmetGenerator'
import BasicTemplate from '../templates/BasicTemplate'
import { getIdFromUrl } from '../utils/createBlogUrl'
import { tsToDate } from '../utils/tsToDate'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const modules = {
    toolbar: false
}

function BlogRead() {
    const [blog, setBlog] = useState('')
    const [loading, setLoading] = useState(true)

    const params = useParams()
    const history = useHistory()

    function viewMoreBlog() {
        history.push('/blogs')
    }

    function getBlog() {
        setLoading(true)
        DB.collection('blogs')
            .doc(getIdFromUrl(params?.id))
            .get()
            .then((doc) => {
                if (doc.exists) {
                    setLoading(false)
                    setBlog({ id: doc.id, ...doc.data() })
                } else {
                    history.push('/blogs')
                }
            })
            .catch((error) => {
                console.log('Error getting blog', error)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getBlog()
        // eslint-disable-next-line 
    }, [params])

    return (
        <>
            {!loading && (
                <HelmetGenerator
                    details={{
                        title: `${blog?.title} - teacherind.com`,
                        description: blog?.description,
                        canonical: `https://teacherind.com/blogs/read/${params?.id}`
                    }}
                />
            )}
            <BasicTemplate>
                <BlogContainer>
                    <Container>
                        <TitleContainer>
                            <Header>Teacherind Blogs</Header>
                            <Tagline>
                                Kerala’s Leading One to One Tuition Platform
                            </Tagline>
                        </TitleContainer>
                        {!!blog && (
                            <ReadContainer>
                                <TitleImage>
                                    <img
                                        src={blog?.titleImage}
                                        alt={blog?.title}
                                    />
                                </TitleImage>
                                <BlogContentContainer>
                                    <BlogTitle>{blog?.title}</BlogTitle>
                                    <DateAndDuration>
                                        <p>{tsToDate(blog?.timestamp)[0]}</p>
                                        <p>{blog?.duration}</p>
                                    </DateAndDuration>
                                    <EditorContainer>
                                        <ReactQuill
                                            value={blog?.content}
                                            modules={modules}
                                            theme="snow"
                                            readOnly
                                        />
                                    </EditorContainer>
                                </BlogContentContainer>
                            </ReadContainer>
                        )}
                        {loading && <Loading>Loading...</Loading>}
                    </Container>
                    <ViewMore>
                        <ViewMoreButton onClick={viewMoreBlog}>
                            View More Blogs
                        </ViewMoreButton>
                    </ViewMore>
                </BlogContainer>
            </BasicTemplate>
        </>
    )
}

const BlogContainer = styled.div`
    background-color: #f2f3f7;
    min-height: 80vh;
    font-family: 'Quicksand', sans-serif;
    padding-bottom: 40px;
`

const ReadContainer = styled.div`
    max-width: 700px;
    margin: auto;
    background-color: #fff;
    margin-top: 20px;
`
const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    padding-top: 40px;
    border-bottom: 1px dashed #750092;
`

const Header = styled.h1`
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
`

const Tagline = styled.p`
    color: #555;
    text-align: center;
    max-width: 300px;
`

const Loading = styled.p`
    color: #333;
    text-align: center;
    margin-top: 50px;
    font-weight: 500;
`

const TitleImage = styled.div`
    width: 100%;
    height: 400px;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`

const BlogContentContainer = styled.div`
    padding: 20px;
`

const BlogTitle = styled.h1`
    color: #2e3a59;
    font-weight: 600;
    font-size: 28px;
    text-align: left;
    margin: 10px 0;
`
const DateAndDuration = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    p {
        color: #6f767f;
        font-size: 14px;
    }
`

const EditorContainer = styled.div`
    .ql-editor {
        min-height: 375px;
        font-size: 16px;
        padding: 0;
        font-family: 'Quicksand', sans-serif;
    }
    .ql-container,
    .ql-toolbar,
    .ql-snow {
        border: none;
    }
    .ql-container {
        width: 100%;
        margin: auto;
    }

    .ql-editor .ql-video {
        width: 100%;
        height: 100%;
        min-height: 350px;
    }
`
const ViewMore = styled.div`
    width: 100%;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ViewMoreButton = styled.button`
    color: #378ef7;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 15px;
    padding: 15px 20px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
`

export default BlogRead

import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded'
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'

export default function ErrorStatus({ error, success, loading }) {
    return (
        <>
            {!!error && (
                <p className="font-Quick font-med error_msg">
                    <ErrorOutlineRoundedIcon />
                    <span>{error}</span>
                </p>
            )}
            {!!success && (
                <p className="font-Quick font-med success_msg">
                    <CheckRoundedIcon />
                    <span>{success}</span>
                </p>
            )}
            {!!loading && (
                <p className="font-Quick font-med loading_msg">
                    <QueryBuilderRoundedIcon />
                    <span>{loading}</span>
                </p>
            )}
        </>
    )
}

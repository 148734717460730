import { Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { DB } from '../../firebase'
import BlogCard from '../partialComponents/BlogCard'
import AdminPanelTemplate from '../templates/AdminPanelTemplate'
import { createBlogUrl } from '../utils/createBlogUrl'

function AdminBlogListing() {
    const [blogs, setBlogs] = useState([])
    const [loading, setLoading] = useState(true)

    const history = useHistory()

    function blogHandler(id, title) {
        const url = createBlogUrl(id, title)
        history.push(`/admin/blogs/view/${url}`)
    }

    function getBlogs() {
        setLoading(true)
        DB.collection('blogs')
            .orderBy('timestamp', 'desc')
            .get()
            .then((querySnapshot) => {
                let blogArray = []
                querySnapshot.forEach((doc) => {
                    blogArray.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                setLoading(false)
                setBlogs(blogArray)
            })
            .catch((error) => {
                console.log('Error getting blogs')
            })
    }

    useEffect(() => {
        getBlogs()
    }, [])

    return (
        <AdminPanelTemplate>
            <Heading>Teacherind Blogs</Heading>

            <BlogsLists>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    {!!blogs.length &&
                        !loading &&
                        blogs.map((blog, key) => (
                            <Grid key={key} item xs={12} sm={6}>
                                <BlogCard
                                    title={blog?.title}
                                    blogHandler={blogHandler}
                                    id={blog?.id}
                                    description={blog?.description}
                                    titleImage={blog?.titleImage}
                                    duration={blog.duration}
                                />
                            </Grid>
                        ))}

                    {!blogs.length && !loading && (
                        <NoDataFound>No Blogs Found</NoDataFound>
                    )}

                    {!blogs.length && loading && (
                        <NoDataFound>Loading...</NoDataFound>
                    )}
                </Grid>
            </BlogsLists>
        </AdminPanelTemplate>
    )
}

const Heading = styled.h1`
    color: #333;
    text-align: center;
    font-weight: 700;
    font-size: 1.6rem;
    margin-top: 1rem;
`

const BlogsLists = styled.div`
    margin: 30px auto;
`

const NoDataFound = styled.p`
    color: #333;
    text-align: center;
    margin-top: 50px;
    font-weight: 500;
`

export default AdminBlogListing

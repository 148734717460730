import BecomeTutor from '../partialComponents/BecomeTutor'
import TutorHero from '../partialComponents/TutorHero'
import BasicTemplate from '../templates/BasicTemplate'
import { useEffect } from 'react'

export default function Tutor() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <BasicTemplate>
            <div className="tutor_page">
                <TutorHero />
                <BecomeTutor />
            </div>
        </BasicTemplate>
    )
}

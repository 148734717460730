import { Box, Container, Grid, Hidden } from '@material-ui/core'
import { Link } from 'react-router-dom'
import CustomContainer from './CustomContainer'
import smilingGirl from '../../Assets/layout_images/smiling_girl.png'
import heroHomeWave from '../../Assets/layout_images/home_hero_wave.png'
import TypingEffect from './TypingEffect'

export default function HeroHome() {
    return (
        <div className="hero_home">
            <div className="hero_wave_home">
                <img src={heroHomeWave} alt="heroHomeWave" />
            </div>
            <Container maxWidth="lg">
                <CustomContainer>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={6}>
                            <h1 className="font-Quick font-bold hero__heading">
                                {`Learn `}

                                <TypingEffect
                                    words={[
                                        'creatively',
                                        'passionately',
                                        'joyfully'
                                    ]}
                                    colors={['#58BCEE', '#3FABA5', '#CEAAA1']}
                                />

                                <br />
                                <span className="heading_hero_home_2">
                                    {`With your personal tutor.`}
                                </span>
                            </h1>

                            <p className="font-Quick font-med hero__content">
                                <b>One</b> student. <b>One</b> Teacher
                                <br />
                                100% Interactive classes for Class 1-12
                            </p>
                            <Hidden smDown>
                                <Link
                                    className="home_hero_btn font-Quick font-bold font-16"
                                    to="/form"
                                    style={{
                                        marginTop: '20px',
                                        display: 'inline-block',
                                        marginLeft: '20px'
                                    }}
                                >
                                    Book A Free Class
                                </Link>
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img
                                    className="home_HeroIllu"
                                    src={smilingGirl}
                                    alt="smilingGirl"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </CustomContainer>
            </Container>
        </div>
    )
}

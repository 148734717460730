import academic from '../../Assets/layout_images/academic.png'
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded'

export default function Academic() {
    
    return (
        <div className="academic-wrap">
            <img className='academic' src={academic} alt="" />
            <div>
                <h2 className="font-Quick font-semi">Meet Your Academic Friend Initiative</h2>
                <p className="font-Quick font-med">At teacherInd, we aim for holistic development of each child’s character, learning aptitude, and overall outlook on their student life. Each student has a dedicated companion to:</p>
                <div className="get_started_points">
                    <p className="font-Quick font-med">
                    <ArrowRightRoundedIcon
                                                fontSize="large"
                                                style={{
                                                    color: '#FC7966'
                                                }}
                                            />Gauge student learning capacity</p>
                    <p className="font-Quick font-med">
                    <ArrowRightRoundedIcon
                                                fontSize="large"
                                                style={{
                                                    color: '#FC7966'
                                                }}
                                            />Review insights from assessments</p>
                    <p className="font-Quick font-med">
                    <ArrowRightRoundedIcon
                                                fontSize="large"
                                                style={{
                                                    color: '#FC7966'
                                                }}
                                            />Develop individualized study plans</p>
                    <p className="font-Quick font-med">
                    <ArrowRightRoundedIcon
                                                fontSize="large"
                                                style={{
                                                    color: '#FC7966'
                                                }}
                                            />Apply personalized learning methods</p>    
                </div>
            </div>
        </div>
    )

}
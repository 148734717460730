import { Container, Hidden } from '@material-ui/core'
import { Link } from 'react-router-dom'
import btechHeroImage from '../../Assets/layout_images/btechHeroImage.png'
import CustomContainer from './CustomContainer'

export default function BtechHero() {
    return (
        <div className="btech_hero_section">
            <Container maxWidth="lg">
                <CustomContainer>
                    <div className="btech_hero_section_wrapper">
                        <div className="btech_hero_image">
                            <img src={btechHeroImage} alt="btech hero" />
                        </div>
                        <div className="btech_hero_content">
                            <h1 className="font-Rale font-xbold">
                                Don’t worry about <span>B.tech</span> anymore.
                            </h1>
                            <p className="font-Quick font-med">
                                One Student. One Teacher.
                            </p>
                            <Hidden smDown>
                                <div className="btech_button">
                                    <Link
                                        to="/form"
                                        className="btech_book_button font-Quick font-bold"
                                    >
                                        Book A Free Class
                                    </Link>
                                </div>
                            </Hidden>
                        </div>
                    </div>
                </CustomContainer>
            </Container>
        </div>
    )
}

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Error from './pages/Error'
import Home from './pages/Home'
import StateSyllabus from './pages/StateSyllabus'
import Cbse from './pages/Cbse'
import Icse from './pages/Icse'
import Igcse from './pages/Igcse'
import Btech from './pages/Btech'
import ContactUs from './pages/ContactUs'
import Faqs from './pages/Faqs'
import FormPage from './pages/FormPage'
import PrivacyPolicy from './pages/PrivacyPolicy'
// import Robotics from './pages/Robotics'
import SpokenEnglish from './pages/SpokenEnglish'
import Tutor from './pages/Tutor'
import TutorGoogleForm from './pages/TutorGoogleForm'
import FormSuccess from './partialComponents/FormSuccess'
import AdminPanel from './pages/AdminPanel'
import ProtectedRoute from './utils/ProtectedRoute'
import AdminLogin from './pages/AdminLogin'
import Leads from './pages/Leads'
import BlogEditor from './pages/BlogEditor'
import AdminBlogListing from './pages/AdminBlogListing'
import AdminBlogView from './pages/AdminBlogView'
import Blogs from './pages/Blogs'
import BlogRead from './pages/BlogRead'

export default function Main() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/state" component={StateSyllabus} />
                <Route path="/cbse" component={Cbse} />
                <Route path="/igcse" component={Igcse} />
                <Route path="/icse" component={Icse} />
                <Route path="/btech" component={Btech} />
                <Route path="/contact-us" exact component={ContactUs} />
                <Route path="/faqs" exact component={Faqs} />
                <Route path="/form" exact component={FormPage} />
                <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                <Route path="/spoken-english" exact component={SpokenEnglish} />
                <Route path="/tutor" exact component={Tutor} />
                <Route path="/thankyou" exact component={FormSuccess} />
                <Route
                    path="/tutor/register"
                    exact
                    component={TutorGoogleForm}
                />
                <Route path="/blogs" exact component={Blogs} />
                <Route path="/blogs/read/:id" exact component={BlogRead} />

                {/* ADMIN PANEL */}

                <Route path="/admin/login" exact component={AdminLogin} />
                <ProtectedRoute path="/admin" exact component={AdminPanel} />
                <ProtectedRoute path="/admin/leads" exact component={Leads} />
                <ProtectedRoute
                    path="/admin/blogs/create"
                    exact
                    component={BlogEditor}
                />
                <ProtectedRoute
                    path="/admin/blogs/view"
                    exact
                    component={AdminBlogListing}
                />
                <ProtectedRoute
                    path="/admin/blogs/view/:id"
                    exact
                    component={AdminBlogView}
                />
                <ProtectedRoute
                    path="/admin/blogs/edit/:id"
                    exact
                    component={AdminBlogView}
                />

                <Route path="*" exact component={Error} />
            </Switch>
        </Router>
    )
}

import { Container } from '@material-ui/core'
import { Link } from 'react-router-dom'
import formRocket from '../../Assets/layout_images/formRocket.svg'

export default function FormSuccess() {
    return (
        <div className="form_page_section">
            <Container>
                <div className="form_page_wrapper">
                    <div className="form_card">
                        <div className="success_card_form">
                            <p className="font-Quick font-med">
                                Thank You for Booking Our team will contact you
                                soon.
                            </p>
                            <img src={formRocket} alt="formRocket" />

                            <Link to="/" className="font-Quick font-med">
                                Explore More
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

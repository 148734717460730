import checkTrue from '../../Assets/layout_icons/checkTrue.png'
import unique from '../../Assets/layout_images/unique.svg'

export default function UniqueHome() {
    const pointsUnique = [
        {
            title: 'Student-teacher Bond',
            content:
                'We bring back humanity into a student-teacher relationship.'
        },
        {
            title: 'Flexible timing',
            content:
                'Both the tutor and student can fix their time-table through mutual understanding.'
        },
        {
            title: 'Affordable fees',
            content:
                'TeacherInd provides most affordable tuition fees in India.'
        }
    ]
    return (
        <div className="unique_home">
            <div>
                <h2 className="font-Quick font-semi">
                    What makes teacherInd UNIQUE?
                </h2>
                <div className="home_teacherind_unique">
                    <div className="lg_unique_img">
                        <img src={unique} alt="unique" />
                    </div>
                    <div className="unique_points">
                        {pointsUnique.map((el, key) => (
                            <div key={key}>
                                <img src={checkTrue} alt="tick" />
                                <div>
                                    <h3 className="font-Quick font-bold">
                                        {el.title}
                                    </h3>
                                    <p className="font-Quick font-med">
                                        {el.content}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

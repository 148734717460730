export const faqs = {
    btech: [
        {
            qn: 'Are you struggling to score good grade points or pass a supplementary examination?',
            ans: 'At teacherInd, you get a personal tutor exclusively for each subject. All tutors are subject experts with exceptional academic performance in their B-tech and M-Tech endeavours. Feel free to ask any doubts. You will also get crash courses to ace your supplementary examinations upon request.'
        },
        {
            qn: 'Are you struggling to get affordable tuitions?',
            ans: 'We do understand the financial situation of most B-Tech students. The expense of college fees, hostel fees, academics etc is a huge burden. Hence, we have calculated our Tuition fees based on considerate discussions with many students, parents and tutors, hence you will surely get the best tutor at the best fees for each subject.'
        },
        {
            qn: 'Are you struggling with online tuition with so many students being taught at a time?',
            ans: 'The B-tech syllabus is no joke and is tough to dig into many concepts and equations without personal attention. At teacherInd, you will get a teacher exclusively for you and nobody else and hence you need not worry about any time lag or any language issues. Hence, you need not worry about getting lost in a huge online classroom with little freedom to ask doubts. You will be treated well on a One-to-One basis with full focus only on your education.'
        },
        {
            qn: 'Do you feel that online teachers lack the equipment to teach you well?',
            ans: 'At teacherInd, we are better than all other online tuitions because our teachers mandatorily possess a Digiboard / WhiteBoard to help convey topics better.'
        }
    ],
    robotics: [
        {
            qn: 'Are you a Tech Enthusiast fascinated by technology?',
            ans: 'At TeacherInd, passionate mentors share their passion to give the students  practical and comprehensive knowledge on robotics.'
        },
        {
            qn: 'Struggling to find quality robotics classes?',
            ans: 'Our tutors are engineers who can make a difference in your life. Our tutors have the experience of conducting numerous workshops to both Graduates and school students in Kerala across the past 4 years'
        },
        {
            qn: 'Is it affordable?',
            ans: 'We have calculated our fees based on considerate discussions with many students, parents and teachers, hence you will surely get the best teachers at the best fees.'
        },
        {
            qn: 'Are you struggling to find a platform to raise your doubts and get individual attention?',
            ans: 'At teacherInd, You get a teacher for you with individual attention and hence you need not worry about any time lag or any language issues.'
        },
        {
            qn: 'Do you feel that online teachers lack the equipment to teach you well?',
            ans: 'At TeacherInd, we will ship the Robotic kits and learn together online in batches of 5 students.'
        },
        {
            qn: 'When will I get my kit?',
            ans: 'You will receive the kit within 7 days from the date of booking the course.'
        }
    ],
    state: [
        {
            qn: 'What subjects are covered in online tuition for Class 8 ICSE?',
            ans: 'Our online tuition for Class 8 ICSE covers all core subjects, including Mathematics, Science, and English, to build a strong academic base.'
        },
        {
            qn: 'What are the online tuition fees for Class 8, 9, and 10 ICSE?',
            ans: 'Our online tuition fees for Class 8, 9, and 10 ICSE are competitively set to provide top teachers and flexible packages for all needs.'
        },
        {
            qn: 'Are large online tuition groups making it hard for you to keep up?',
            ans: 'With TeacherInd, enjoy personal tutor for a one on one experience, free of delays and language confusion.'
        },
        {
            qn: 'Are you worried that online tuition would not have the right tools for effective instruction?',
            ans: 'At TeacherInd, we require our teachers to use DigiBoards and Whiteboards in our online tuition, ensuring a better learning experience for you.'
        },
        {
            qn: 'What is the benefit of enrolling in online tuition for Class 10 ICSE?',
            ans: 'Online tuition for Class 10 ICSE provides personalized learning and flexible scheduling, helping students grasp complex topics at their own pace.'
        },
        {
            qn: 'Is there online tuition for Class 9 ICSE available on this platform?',
            ans: 'Yes, At TeacherInd we offer specialized online tuition for Class 9 ICSE to support students with tailored guidance and exam preparation.'
        }
    ],
    spokenEnglish: [
        {
            qn: 'Are you struggling with broken english?',
            ans: 'Tutors at TeacherInd can help you cope up with fundamentals of spoken english. You can only learn English through frequent conversations with your tutor. You will not have to face another embarrassing situation without English anymore.'
        },
        {
            qn: 'Are you afraid of speaking Broken-English in front of a classroom?',
            ans: 'TeacherInd employs a One Student One Tutor methodology which provides individual attention through live classes. You can ask any silly question and learn to speak english without tension. The classes are strictly personalized and will adjust to your needs.'
        },
        {
            qn: 'Do you want a comprehensive english course?',
            ans: 'You will learn about Spoken English, English grammar, English pronunciation and if you want more, just ask and you shall receive.'
        },
        {
            qn: 'How long will the tuition take to complete?',
            ans: 'It totally depends on your pace of learning, your timing, your determination and your current state of preparation. You can take as many classes as you need before you perfect your English.'
        }
    ],
    cbse: {
        class7: [
            {
                qn: 'What subjects are covered in online tuition for Class 7 CBSE?',
                ans: 'Our online tuition for Class 7 CBSE covers all core subjects, including Mathematics, Science, and English, to build a strong academic base'
            },
            {
                qn: 'What are the online tuition fees for Class 4,5,6 and 7 CBSE?',
                ans: 'Our online tuition fees for Class 4,5,6 and 7 CBSE are competitively set to provide top teachers and flexible packages for students of all skill levels. Call us now to get your personalized fee schedule'
            },
            {
                qn: 'Are large numbers of students in your tuition class making it hard for you to keep up?',
                ans: 'With teacherInd, enjoy your personal tutor through a one on one experience. You will be the only student in your class'
            },
            {
                qn: 'Do you provide online tuition in Kerala?',
                ans: 'Yes, we provide online tuition in Kerala, Delhi Bangalore, Chennai etc. Actually, we provide Online tuition in India as a whole'
            },
            {
                qn: 'What is the benefit of enrolling in online tuition for Class 4 CBSE?',
                ans: 'Online tuition for Class 4 CBSE provides personalized learning and flexible scheduling, helping students grasp complex topics at their own pace'
            },
            {
                qn: 'Is there online tuition for Class 6 CBSE available on this platform?',
                ans: 'Yes, At teacherInd we offer specialized online tuition for Class 6 CBSE to support students with tailored guidance and exam preparation'
            }
        ],
        class8: [
            {
                qn: '1.What subjects are covered in online tuition for Class 8 CBSE?',
                ans: 'Our online tuition for Class 8 CBSE covers all core subjects, including Mathematics, Science, and English, to build a strong academic base.'
            },
            {
                qn: 'What are the online tuition fees for Class 8, 9, and 10 CBSE?',
                ans: 'Our online tuition fees for Class 8, 9, and 10 CBSE are competitively set to provide top teachers and flexible packages for students of all skill levels. Call us now to get your personalized fee schedule.'
            },
            {
                qn: 'Are large numbers of students in your tuition class making it hard for you to keep up?',
                ans: 'With teacherInd, enjoy your personal tutor through a one on one experience. You will be the only student in your class.'
            },
            {
                qn: 'Do you provide online tuition in Kerala?',
                ans: 'Yes, we provide online tuition in Kerala, Delhi Bangalore, Chennai etc. Actually, we provide Online tuition in India as a whole.'
            },
            {
                qn: 'What is the benefit of enrolling in online tuition for Class 10 CBSE?',
                ans: 'Online tuition for Class 10 CBSE provides personalized learning and flexible scheduling, helping students grasp complex topics at their own pace.'
            },
            {
                qn: 'Is there online tuition for Class 9 CBSE available on this platform?',
                ans: 'Yes, At teacherInd we offer specialized online tuition for Class 9 CBSE to support students with tailored guidance and exam preparation.'
            }
        ]
    },
    icse: {
        class7: [
            {
                qn: 'What subjects are covered in online tuition for Class 7 ICSE?',
                ans: 'Our online tuition for Class 7 ICSE covers all core subjects, including Mathematics, Science, and English, to build a strong academic base'
            },
            {
                qn: 'What are the online tuition fees for Class 4, 5, 6 and 7 ICSE?',
                ans: 'Our online tuition fees for Class 4, 5, 6, and 7 ICSE are competitively set to provide top teachers and flexible packages for students of all skill levels. Call us now to get your personalized fee schedule'
            },
            {
                qn: 'Are large numbers of students in your tuition class making it hard for you to keep up?',
                ans: 'With teacherInd, enjoy your personal tutor through a one on one experience. You will be the only student in your class'
            },
            {
                qn: 'Do you provide online tuition in Kerala?',
                ans: 'Yes, we provide online tuition in Kerala, Delhi Bangalore, Chennai etc. Actually, we provide Online tuition in India as a whole'
            },
            {
                qn: 'What is the benefit of enrolling in online tuition for Class 4 ICSE?',
                ans: 'Online tuition for Class 4 ICSE provides personalized learning and flexible scheduling, helping students grasp complex topics at their own pace'
            },
            {
                qn: 'Is there online tuition for Class 6 ICSE available on this platform?',
                ans: 'Yes, At teacherInd we offer specialized online tuition for Class 6 ICSE to support students with tailored guidance and exam preparation'
            }
        ],
        class8: [
            {
                qn: '1.What subjects are covered in online tuition for Class 8 ICSE?',
                ans: 'Our online tuition for Class 8 CBSE covers all core subjects, including Mathematics, Science, and English, to build a strong academic base.'
            },
            {
                qn: 'What are the online tuition fees for Class 8, 9, and 10 CBSE?',
                ans: 'Our online tuition fees for Class 8, 9, and 10 CBSE are competitively set to provide top teachers and flexible packages for students of all skill levels. Call us now to get your personalized fee schedule.'
            },
            {
                qn: 'Are large numbers of students in your tuition class making it hard for you to keep up?',
                ans: 'With teacherInd, enjoy your personal tutor through a one on one experience. You will be the only student in your class.'
            },
            {
                qn: 'Do you provide online tuition in Kerala?',
                ans: 'Yes, we provide online tuition in Kerala, Delhi Bangalore, Chennai etc. Actually, we provide Online tuition in India as a whole.'
            },
            {
                qn: 'What is the benefit of enrolling in online tuition for Class 10 CBSE?',
                ans: 'Online tuition for Class 10 CBSE provides personalized learning and flexible scheduling, helping students grasp complex topics at their own pace.'
            },
            {
                qn: 'Is there online tuition for Class 9 CBSE available on this platform?',
                ans: 'Yes, At teacherInd we offer specialized online tuition for Class 9 CBSE to support students with tailored guidance and exam preparation.'
            }
        ]
    }
}

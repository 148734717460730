export const whoCanBenefit = {
    state: [
        'Students struggling with basics.',
        'Students needing guided revision.',
        'Students aiming to improve self-study.',
        'Students uncomfortable with group study.',
        'Students who require frequent help to understand concepts.',
        'Students who find it difficult to learn in a group.',
        'Students who find it difficult to ask doubts in a classroom.'
    ],
    btech: [
        'The Last-minute learner.',
        'Students who require help for revision.',
        'Students who require frequent help to grasp concepts.',
        'Students who find it difficult to learn in a group.',
        'Students who require faster learning techniques.',
        'Students who lack fundamentals in certain subjects.',
        'Students who find it difficult to ask doubts in a classroom.'
    ],
    robotics: [
        ' Robot lover.',
        ' Students who require frequent help to grasp concepts.',
        'The innovator.',
        'Students who need to brush up on fundamentals.',
        'Students who require help with projects.',
        'Students who find it difficult to learn in a group.'
    ]
}

import { Container, Grid } from '@material-ui/core'
import React from 'react'
import { useLocation } from 'react-router-dom'
import StateBenefitCard from './StateBenefitCard'

export default function StateBenefits({ benefits }) {
    const location = useLocation()
    const pathnameList = location.pathname.split('/')
    const mainPath = pathnameList[1]
    const subPath = pathnameList[2]
    const isClass8 =  subPath?subPath.includes('8') : false

    let benefitsToRender = []

    if (mainPath === 'cbse' || mainPath === 'icse' || mainPath === 'igcse') {
        benefitsToRender = isClass8 ? benefits.class8 : benefits.class7
    } else {
        benefitsToRender = benefits 
    }
    return (
        <div className="state_benefits">
            <h2 className="font-Quick font-semi">
                Why Online Tuition With TeacherInd?
            </h2>
            <p className="font-Quick font-med">
                We make the lives of our students and their parents easier.
            </p>
            <div className="benefit_cards_state">
                <Container>
                    <Grid container spacing={3} justify="center">
                        {benefitsToRender.map((el, key) => (
                            <StateBenefitCard
                                image={el.image}
                                title={el.title}
                                content={el.content}
                                key={key}
                            />
                        ))}
                    </Grid>
                </Container>
            </div>
        </div>
    )
}

import FaqContainer from '../partialComponents/FaqContainer'
import BasicTemplate from '../templates/BasicTemplate'
import { faqs } from '../utils/faqs'
import { useEffect } from 'react'

export default function Faqs() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // function combineFaq() {
    //     let arr = []
    //     Object.keys(faqs).forEach((el) => {
    //         faqs[el].forEach((item) => {
    //             arr.push(item)
    //         })
    //     })
    //     return arr
    // }

    return (
        <BasicTemplate>
            <FaqContainer faqList={faqs.state} />
        </BasicTemplate>
    )
}

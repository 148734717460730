import { Container, Hidden } from '@material-ui/core'
import CustomContainer from './CustomContainer'
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded'
import tutorHero from '../../Assets/layout_images/tutorHero.svg'
import { Link } from 'react-router-dom'

export default function TutorHero() {
    return (
        <Container>
            <CustomContainer>
                <div className="tutor_hero_section">
                    <Hidden mdUp>
                        <div className="tutor_how_to_get_started_section_mob">
                            <h2 className="font-Quick font-semi">
                                Benefits of becoming a tutor
                            </h2>
                            <div>
                                <div className="image_mob_get_started">
                                    <img src={tutorHero} alt="tutorHero" />
                                </div>
                                <div className="get_started_points">
                                    {[
                                        'Teach at anytime.',
                                        'Timely payments',
                                        'Ready supply of students',
                                        'Great Part-time income source.',
                                        'Only One student at a time'
                                    ].map((el, key) => (
                                        <p
                                            className="font-Quick font-med"
                                            key={key}
                                        >
                                            <ArrowRightRoundedIcon
                                                fontSize="large"
                                                style={{
                                                    color: '#FC7966'
                                                }}
                                            />
                                            {el}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Hidden>
                    <Hidden smDown>
                        <div className="tutor_how_to_get_started_section_desk">
                            <img src={tutorHero} alt="tutorHero" />
                            <div>
                                <h2 className="font-Quick font-semi">
                                    Benefits of becoming a tutor
                                </h2>
                                <div className="get_started_points">
                                    {[
                                        'Teach at anytime.',
                                        'Timely payments',
                                        'Ready supply of students',
                                        'Great Part-time income source.',
                                        'Only One student at a time'
                                    ].map((el, key) => (
                                        <p
                                            className="font-Quick font-med"
                                            key={key}
                                        >
                                            <ArrowRightRoundedIcon
                                                fontSize="large"
                                                style={{
                                                    color: '#FC7966'
                                                }}
                                            />
                                            {el}
                                        </p>
                                    ))}
                                </div>
                                <div className="get_started_button_tutor">
                                    <Link
                                        to="/tutor/register"
                                        className="tutor_book_button font-Quick font-bold"
                                    >
                                        Apply Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Hidden>
                </div>
            </CustomContainer>
        </Container>
    )
}

import { Box } from '@material-ui/core'
import img_01 from '../../Assets/layout_images/img_01.svg'
import img_02 from '../../Assets/layout_images/img_02.svg'
import img_03 from '../../Assets/layout_images/img_03.svg'
import img_04 from '../../Assets/layout_images/img_04.svg'
import img_05 from '../../Assets/layout_images/img_05.svg'
import img_06 from '../../Assets/layout_images/img_06.svg'

export default function WhyNeedUsHome() {
    return (
        <div className="why_need_us_home">
            <div>
                <h2 className="font-Quick font-semi">
                    Why do students need us?
                </h2>

                <Box className="student_need_home">
                    <div className="student_need_points_home">
                        {[
                            ['Lack of individual attention in school', img_01],
                            ['Fear of asking doubts', img_02],
                            [
                                'Each student has a unique pace of learning',
                                img_03
                            ],
                            ['Ineffective learning methods', img_04],
                            ['Lack of fundamentals', img_05],
                            ['Tuitions within your budgets', img_06]
                        ].map((el, key) => (
                            <div
                                key={key}
                                className={`student_need_point_home_text${
                                    key % 2 === 0 ? 1 : 2
                                }`}
                            >
                                <div>
                                    <p className="font-Quick font-med">
                                        {el[0]}
                                    </p>
                                    <img src={el[1]} alt={el[0]} />
                                </div>
                            </div>
                        ))}
                    </div>
                </Box>
            </div>
        </div>
    )
}

import Abdullah from '../../Assets/testimonials/Abdullah.jpg'
import Faseem from '../../Assets/testimonials/Faseem.jpg'
import gopiKrishnan from '../../Assets/testimonials/gopiKrishnan.jpg'
import jyothisThreesa from '../../Assets/testimonials/jyothisThreesa.jpg'
import lakshmiPriya from '../../Assets/testimonials/lakshmiPriya.jpg'
import sufiyan from '../../Assets/testimonials/sufiyan.jpg'
import taniaThomas from '../../Assets/testimonials/taniaThomas.png'

export const testimonials = [
    {
        name: 'Gopi Krishnan',
        img: gopiKrishnan,
        role: '5th Standard Student',
        saying: 'A great solution for anyone who is struggling in mathematics.'
    },
    {
        name: 'Abdullah B',
        img: Abdullah,
        role: 'B.Tech Student',
        saying:
            'My first time taking a class in this format. Very effective. It allowed for me to learn at my pace.'
    },
    {
        name: 'Faseen M',
        img: Faseem,
        role: 'B.Tech Student',
        saying: `The best lectures and notes. Thanks to TeacherInd for an excellent support and co-operation.`
    },
    {
        name: 'Lakshmipriya B',
        img: lakshmiPriya,
        role: 'B.Tech Student',
        saying:
            'One to one teaching helped me to understand the concepts very well and to clarify my doubts &amp; solve previous year question papers.'
    },
    {
        name: 'Jyothis Treesa',
        img: jyothisThreesa,
        role: '12th Student',
        saying: `I couldn't clear my doubts in an online class of 55 in subjects like accountancy. TeacherInd tutors helped me get individual attention.`
    },
    {
        name: 'Tania Thomas',
        img: taniaThomas,
        role: '12th Student',
        saying:
            'Had a lot of tension regarding taking entrance oriented classes and regular classes together. TeacherInd and helped me follow regular school portions in depth and clarity.'
    },
    {
        name: 'Mohammed Sufiyan',
        img: sufiyan,
        role: 'Student',
        saying: `The Robotics course is very interesting. I made my first TI-Bot. `
    },
    {
        img: taniaThomas,
        role: 'Student',
        saying:
            'Online tuition for science and mathematics at TeacherInd really changed my Class 10 experience. The tutors made tough concepts easy to understand, and I now feel much more confident for my exams!'
    },
    {
        img: taniaThomas,
        role: 'Parent',
        saying:
            'We are grateful for TeacherInds online tuition for mathematics. My child has improved greatly in Class 10, and the tutors make learning engaging. Highly recommended for quality education.'
    },
    {
        img: taniaThomas,
        role: 'Student',
        saying:
            'I had a hard time with Hindi and English in Class 9 ICSE, but TeacherInd’s online tuition for Hindi and English turned things around for me. The support from tutors made all the difference.'
    }
]

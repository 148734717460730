import facebook from '../../Assets/layout_icons/facebook.svg'
import whatsapp from '../../Assets/layout_icons/whatsapp.svg'
import instagram from '../../Assets/layout_icons/instagram.svg'
import google from '../../Assets/layout_icons/google.svg'
import youtube from '../../Assets/layout_icons/youtube.svg'
import linkedin from '../../Assets/layout_icons/linkedin.svg'

export default function SocialMediaFooter() {
    const socialMediaLinks = [
        {
            link: 'https://www.facebook.com/teacherindclasses',
            icon: facebook,
            alt: 'facebook'
        },
        {
            link: 'https://www.instagram.com/teacherindclasses',
            icon: instagram,
            alt: 'instagram'
        },
        {
            link: 'https://g.page/teacherind/review?gm',
            icon: google,
            alt: 'google'
        },
        {
            link: 'https://www.youtube.com/channel/UC2-cREBHUI8QkME9HKDeEYg',
            icon: youtube,
            alt: 'youtube'
        },
        {
            link: 'https://www.linkedin.com/company/quarks-learning',
            icon: linkedin,
            alt: 'linkedin'
        },
        {
            link: 'https://wa.me/+917907361068',
            icon: whatsapp,
            alt: 'whatsapp'
        }
    ]
    return (
        <div className="social_media_footer">
            <h4 className="font-Quick font-med">Follow us on</h4>
            <div className="social_media_links">
                {socialMediaLinks.map((item, key) => (
                    <a
                        key={key}
                        target="_blank"
                        rel="noreferrer"
                        href={item.link}
                    >
                        <img src={item.icon} alt={item.alt} />
                    </a>
                ))}
            </div>
        </div>
    )
}

import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { DB } from '../../firebase'
import AdminPanelTemplate from '../templates/AdminPanelTemplate'
import { getIdFromUrl } from '../utils/createBlogUrl'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Box, Button, Container, Grid, TextField } from '@material-ui/core'
import CustomDialog from '../partialComponents/CustomDialog'
import { tsToDate } from '../utils/tsToDate'
import firebase from 'firebase/app'

const myToolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }, { font: ['Sans Serif', 'monospace'] }],

    [{ size: [] }],
    [{ script: 'sub' }, { script: 'super' }],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }, { background: [] }],

    [{ align: [] }, { indent: '-1' }, { indent: '+1' }],

    ['clean'],
    ['link', 'image', 'video']
]

const modules = {
    toolbar: {
        container: myToolbar,
        handlers: {
            image: imageHandler
        }
    },
    clipboard: {
        // toggle to add extra line breaks when pasting HTML
        matchVisual: false
    }
}

function getModules(edit) {
    if (edit) return modules
    return {
        toolbar: false
    }
}

function imageHandler() {
    var range = this.quill.getSelection()

    var value = prompt(`Please copy & paste the image url here.`)
    if (value) {
        this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER)
    }
}

function AdminBlogView() {
    const [blog, setBlog] = useState('')
    const [title, setTitle] = useState('')
    const [titleImage, setTitleImage] = useState('')
    const [description, setDescription] = useState('')
    const [duration, setDuration] = useState('')
    const [blogContent, setBlogContent] = useState('')
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [deleteBlog, setDeleteBlog] = useState(false)
    const params = useParams()
    const history = useHistory()

    function handleUpdateBlog() {
        if (!blog) return

        DB.collection('blogs')
            .doc(blog?.id)
            .set(
                {
                    title: title,
                    titleImage: titleImage,
                    description: description,
                    duration: `${duration} min read`,
                    content: blogContent,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp()
                },
                { merge: true }
            )
            .then(() => {
                console.log('Updated')
                handleClose()
                setEdit(false)
                getBlog()
            })
            .catch(() => {
                console.log('Error Occured')
            })
    }

    function handleDeleteBlog() {
        if (!deleteBlog || !blog) return

        DB.collection('blogs')
            .doc(blog?.id)
            .delete()
            .then(() => {
                console.log('Deleted')
                handleClose()
                setDeleteBlog(false)
                history.push('/admin/blogs/view')
            })
            .catch(() => {
                console.log('Error Occured')
            })
    }

    const handleClickOpen = () => {
        setOpen(true)
        setDeleteBlog(false)
    }

    const handleClickOpenDeleteBlog = () => {
        setDeleteBlog(true)
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }

    function getBlog() {
        setLoading(true)
        DB.collection('blogs')
            .doc(getIdFromUrl(params?.id))
            .get()
            .then((doc) => {
                if (doc.exists) {
                    setLoading(false)
                    setBlog({ id: doc.id, ...doc.data() })
                    setBlogContent(doc.data()?.content)
                    setTitle(doc.data()?.title)
                    setDuration(
                        parseInt(
                            doc.data()?.duration?.replace(' mins read', '')
                        )
                    )
                    setDescription(doc.data()?.description)
                    setTitleImage(doc.data()?.titleImage)
                } else {
                    history.push('/admin/blogs/view')
                }
            })
            .catch((error) => {
                console.log('Error getting blog')
            })
    }

    useEffect(() => {
        getBlog()
        // eslint-disable-next-line 
    }, [params])

    return (
        <AdminPanelTemplate>
            <Heading>Teacherind Blogs</Heading>
            {!edit && (
                <Button
                    onClick={handleClickOpenDeleteBlog}
                    variant="outlined"
                    color="secondary"
                    style={{ marginRight: '30px' }}
                >
                    DELETE
                </Button>
            )}
            {!edit && (
                <Button
                    onClick={() => setEdit(true)}
                    variant="outlined"
                    color="primary"
                >
                    EDIT
                </Button>
            )}

            {!!blog && (
                <BlogEditorContainer>
                    {!!edit && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    label="Title"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    value={titleImage}
                                    onChange={(e) =>
                                        setTitleImage(e.target.value)
                                    }
                                    label="Title Image Url"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                    label="Short Description"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="flex-end">
                                    <TextField
                                        value={duration}
                                        onChange={(e) =>
                                            setDuration(e.target.value)
                                        }
                                        label="Duration"
                                        type="number"
                                        style={{ marginRight: '5px' }}
                                    />
                                    {` min read`}
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                    {!edit && (
                        <div>
                            <Container maxWidth="sm">
                                <h1>{title}</h1>
                                <p>{description}</p>
                                <p>{tsToDate(blog?.timestamp)[0]}</p>
                                <p>{duration + ' min read'}</p>
                                <div style={{ maxWidth: '400px' }}>
                                    <img
                                        width="100%"
                                        src={titleImage}
                                        alt={title}
                                    />
                                </div>
                            </Container>
                        </div>
                    )}

                    <EditorContainer edit={edit}>
                        <ReactQuill
                            value={edit ? blogContent : blog?.content}
                            onChange={(value) => setBlogContent(value)}
                            placeholder={`Write something...`}
                            modules={getModules(edit)}
                            theme="snow"
                            readOnly={!edit}
                        />
                    </EditorContainer>
                    {!!edit && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            width="100%"
                        >
                            <Button
                                onClick={() => setEdit(false)}
                                variant="contained"
                                color="secondary"
                                size="large"
                                style={{
                                    marginTop: '30px',
                                    marginBottom: '30px',
                                    marginRight: '30px'
                                }}
                            >
                                CANCEL
                            </Button>
                            <Button
                                onClick={handleClickOpen}
                                variant="contained"
                                disabled={
                                    !title ||
                                    !description ||
                                    !titleImage ||
                                    !blogContent ||
                                    !duration
                                }
                                color="primary"
                                size="large"
                                style={{
                                    marginTop: '30px',
                                    marginBottom: '30px'
                                }}
                            >
                                UPDATE
                            </Button>
                        </Box>
                    )}
                </BlogEditorContainer>
            )}

            <CustomDialog
                title={'Are you Sure ?'}
                description={`Blog will be ${
                    deleteBlog ? 'deleted' : 'updated'
                }...`}
                successText={'OK'}
                cancelText={'Cancel'}
                successFunc={deleteBlog ? handleDeleteBlog : handleUpdateBlog}
                open={open}
                handleClose={handleClose}
            />

            {loading && <Loading>Loading...</Loading>}
        </AdminPanelTemplate>
    )
}

const Heading = styled.h1`
    color: #333;
    text-align: center;
    font-weight: 700;
    font-size: 1.6rem;
    margin-top: 1rem;
`

const Loading = styled.p`
    color: #333;
    text-align: center;
    margin-top: 50px;
    font-weight: 500;
`

const EditorContainer = styled.div`
    margin-top: 50px;

    .quill {
        border: 1px solid #ccc;
        background-color: #fff;
    }

    .ql-editor {
        max-height: ${({ edit }) => (edit ? '550px' : 'unset')};
        min-height: 375px;
        font-size: 16px;
        font-family: 'Quicksand', sans-serif;
    }
    .ql-container,
    .ql-toolbar,
    .ql-snow {
        border: none;
    }
    .ql-container {
        max-width: 650px;
        margin: auto;
    }
    .ql-toolbar {
        border-bottom: 1px solid #ccc;
    }
    .ql-editor .ql-video {
        width: 100%;
        height: 100%;
        min-height: 350px;
    }
`

const BlogEditorContainer = styled.div`
    background-color: #ffffff96;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
    width: 100%;
    padding: 30px;
`

export default AdminBlogView

export const createBlogUrl = (id, title) => {
    const titleFiltered =
        title
            ?.split(' ')
            ?.join('-')
            ?.replace(/[^a-zA-Z0-9-_]/g, '')
            ?.split('-')
            ?.filter((el) => el !== '')
            ?.join('-')
            ?.toLowerCase() || ''

    return `${titleFiltered}-${id}`
}

export const getIdFromUrl = (url) => {
    return url?.split('-').pop()
}
